// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-field::placeholder {
  color: #b3b3b3;
  padding-left: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/components/forget-password.modules.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".input-field::placeholder {\n  color: #b3b3b3;\n  padding-left: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
