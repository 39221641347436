// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Otp_container__Ck7dd {
  display: flex;
  justify-content: center;
  background-color: var(--dr-white);
}

@media (max-width: 600px) {
}

/* Media query for screens between 601px and 900px (e.g., tablets) */
@media (min-width: 601px) and (max-width: 900px) {
}

/* Media query for screens between 901px and 1200px (e.g., larger tablets and small desktops) */
@media (min-width: 901px) and (max-width: 1200px) {
}

/* Media query for screens larger than 1200px (e.g., large desktops) */
@media (min-width: 1201px) {
}
`, "",{"version":3,"sources":["webpack://./src/styles/pages/Otp.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,iCAAiC;AACnC;;AAEA;AACA;;AAEA,oEAAoE;AACpE;AACA;;AAEA,+FAA+F;AAC/F;AACA;;AAEA,sEAAsE;AACtE;AACA","sourcesContent":[".container {\n  display: flex;\n  justify-content: center;\n  background-color: var(--dr-white);\n}\n\n@media (max-width: 600px) {\n}\n\n/* Media query for screens between 601px and 900px (e.g., tablets) */\n@media (min-width: 601px) and (max-width: 900px) {\n}\n\n/* Media query for screens between 901px and 1200px (e.g., larger tablets and small desktops) */\n@media (min-width: 901px) and (max-width: 1200px) {\n}\n\n/* Media query for screens larger than 1200px (e.g., large desktops) */\n@media (min-width: 1201px) {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Otp_container__Ck7dd`
};
export default ___CSS_LOADER_EXPORT___;
