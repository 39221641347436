// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dropdown_dropdown__amj5X {
  height: 45px;
  color: var(--dark-charcoal);
  display: flex;
  justify-content: center;
  align-items: center;
}

.Dropdown_dropdown-btn__DeXDC {
  font-size: 15px;
  /* padding: 5px; */
  cursor: pointer;
  border: none;
  /* border-radius: 4px; */
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  align-items: center;
  gap: 3px;
  /* height: 100%; */
  /* background-color: var(--off-white); */
}

.Dropdown_dropdown-item__FfxSV {
  padding: 0 10px;
  text-decoration: none;
  display: block;
  /* color: #333; */
  /* background-color: var(--brown); */
}

.Dropdown_dropdown-item__FfxSV:hover {
  background-color: #f0f0f0;
}

/* media query */
/* Media query for screens smaller than 600px (e.g., smartphones) */
@media (max-width: 900px) {
  .Dropdown_dropdown__amj5X {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/components/Dropdown.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,2BAA2B;EAC3B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,wBAAwB;EACxB,aAAa;EACb,SAAO;EACP,8BAA8B;EAC9B,mBAAmB;EACnB,QAAQ;EACR,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE,eAAe;EACf,qBAAqB;EACrB,cAAc;EACd,iBAAiB;EACjB,oCAAoC;AACtC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA,gBAAgB;AAChB,mEAAmE;AACnE;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".dropdown {\n  height: 45px;\n  color: var(--dark-charcoal);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.dropdown-btn {\n  font-size: 15px;\n  /* padding: 5px; */\n  cursor: pointer;\n  border: none;\n  /* border-radius: 4px; */\n  display: flex;\n  flex: 1;\n  justify-content: space-between;\n  align-items: center;\n  gap: 3px;\n  /* height: 100%; */\n  /* background-color: var(--off-white); */\n}\n\n.dropdown-item {\n  padding: 0 10px;\n  text-decoration: none;\n  display: block;\n  /* color: #333; */\n  /* background-color: var(--brown); */\n}\n\n.dropdown-item:hover {\n  background-color: #f0f0f0;\n}\n\n/* media query */\n/* Media query for screens smaller than 600px (e.g., smartphones) */\n@media (max-width: 900px) {\n  .dropdown {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": `Dropdown_dropdown__amj5X`,
	"dropdown-btn": `Dropdown_dropdown-btn__DeXDC`,
	"dropdown-item": `Dropdown_dropdown-item__FfxSV`
};
export default ___CSS_LOADER_EXPORT___;
