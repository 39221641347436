// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Star_starA__qn-Rv {
  z-index: 10;
  color: var(--white);
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 80%;
}
.Star_star-contentA__xpsrx {
  z-index: 10;
  font-size: 60px;
  color: var(--brown);
  transform: translate(-50%, -50%) rotate(30deg);
  position: absolute;
  top: 50%;
  left: 80%;
  text-align: center;
}
.Star_starB__b6VYr {
  z-index: 10;
  color: var(--white);
  width: 80px;
}
.Star_star-contentB__JYnZo {
  position: relative;
  left: -72px;
  z-index: 10;
  font-size: 20px;
  color: var(--white);
  transform: rotate(30deg);
  text-align: center;
}

/* Media query for screens smaller than 600px (e.g., smartphones) */
@media (max-width: 600px) {
  .Star_starA__qn-Rv {
    width: 60px;
    top: 30%;
  }
  .Star_star-contentA__xpsrx {
    font-size: 15px;
    top: 30%;
  }
}

/* Media query for screens between 601px and 900px (e.g., tablets) */
@media (min-width: 601px) and (max-width: 900px) {
  .Star_starA__qn-Rv {
    width: 120px;
  }
  .Star_star-contentA__xpsrx {
    font-size: 30px;
  }
}

/* Media query for screens between 901px and 1200px (e.g., larger tablets and small desktops) */
@media (min-width: 901px) and (max-width: 1200px) {
}

/* Media query for screens larger than 1200px (e.g., large desktops) */
@media (min-width: 1201px) {
}
`, "",{"version":3,"sources":["webpack://./src/styles/components/Star.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;EACnB,gCAAgC;EAChC,kBAAkB;EAClB,QAAQ;EACR,SAAS;AACX;AACA;EACE,WAAW;EACX,eAAe;EACf,mBAAmB;EACnB,8CAA8C;EAC9C,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,mBAAmB;EACnB,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,eAAe;EACf,mBAAmB;EACnB,wBAAwB;EACxB,kBAAkB;AACpB;;AAEA,mEAAmE;AACnE;EACE;IACE,WAAW;IACX,QAAQ;EACV;EACA;IACE,eAAe;IACf,QAAQ;EACV;AACF;;AAEA,oEAAoE;AACpE;EACE;IACE,YAAY;EACd;EACA;IACE,eAAe;EACjB;AACF;;AAEA,+FAA+F;AAC/F;AACA;;AAEA,sEAAsE;AACtE;AACA","sourcesContent":[".starA {\n  z-index: 10;\n  color: var(--white);\n  transform: translate(-50%, -50%);\n  position: absolute;\n  top: 50%;\n  left: 80%;\n}\n.star-contentA {\n  z-index: 10;\n  font-size: 60px;\n  color: var(--brown);\n  transform: translate(-50%, -50%) rotate(30deg);\n  position: absolute;\n  top: 50%;\n  left: 80%;\n  text-align: center;\n}\n.starB {\n  z-index: 10;\n  color: var(--white);\n  width: 80px;\n}\n.star-contentB {\n  position: relative;\n  left: -72px;\n  z-index: 10;\n  font-size: 20px;\n  color: var(--white);\n  transform: rotate(30deg);\n  text-align: center;\n}\n\n/* Media query for screens smaller than 600px (e.g., smartphones) */\n@media (max-width: 600px) {\n  .starA {\n    width: 60px;\n    top: 30%;\n  }\n  .star-contentA {\n    font-size: 15px;\n    top: 30%;\n  }\n}\n\n/* Media query for screens between 601px and 900px (e.g., tablets) */\n@media (min-width: 601px) and (max-width: 900px) {\n  .starA {\n    width: 120px;\n  }\n  .star-contentA {\n    font-size: 30px;\n  }\n}\n\n/* Media query for screens between 901px and 1200px (e.g., larger tablets and small desktops) */\n@media (min-width: 901px) and (max-width: 1200px) {\n}\n\n/* Media query for screens larger than 1200px (e.g., large desktops) */\n@media (min-width: 1201px) {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"starA": `Star_starA__qn-Rv`,
	"star-contentA": `Star_star-contentA__xpsrx`,
	"starB": `Star_starB__b6VYr`,
	"star-contentB": `Star_star-contentB__JYnZo`
};
export default ___CSS_LOADER_EXPORT___;
