// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar_sidebar__Ddu1z {
  height: 100%;
}
.Sidebar_content__Qxjgl {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

h1 {
  font-size: 15px;
  color: var(--errie-black);
}
p {
  font-size: 13px;
  color: var(--rhine-castle);
}
.Sidebar_line__YhpB\\+ {
  border-top: 2px solid var(--pale-grey);
}
.Sidebar_button__pBc4y {
  border-radius: 10px;
  padding: 13px;
  border: 1px solid var(--brown);
  color: var(--brown);
  background-color: var(--white);
  font-weight: bold;
  width: 100%;
  margin-top: 20px;
  font-size: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/components/Sidebar.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B;AACA;EACE,eAAe;EACf,0BAA0B;AAC5B;AACA;EACE,sCAAsC;AACxC;AACA;EACE,mBAAmB;EACnB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,8BAA8B;EAC9B,iBAAiB;EACjB,WAAW;EACX,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".sidebar {\n  height: 100%;\n}\n.content {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\nh1 {\n  font-size: 15px;\n  color: var(--errie-black);\n}\np {\n  font-size: 13px;\n  color: var(--rhine-castle);\n}\n.line {\n  border-top: 2px solid var(--pale-grey);\n}\n.button {\n  border-radius: 10px;\n  padding: 13px;\n  border: 1px solid var(--brown);\n  color: var(--brown);\n  background-color: var(--white);\n  font-weight: bold;\n  width: 100%;\n  margin-top: 20px;\n  font-size: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `Sidebar_sidebar__Ddu1z`,
	"content": `Sidebar_content__Qxjgl`,
	"line": `Sidebar_line__YhpB+`,
	"button": `Sidebar_button__pBc4y`
};
export default ___CSS_LOADER_EXPORT___;
