// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Plans_container__CG051 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 60px;
  margin-bottom: 60px;
}
.Plans_column__m-iNw {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px;
  border-radius: 8px;
}
.Plans_row__gFUuM {
  display: flex;
  align-items: center;
  gap: 10px;
}
.Plans_premium-button__t61Pu {
  padding: 9px 14px 9px 14px;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  color: var(--white);
  background-color: var(--brown);
}
.Plans_basic-button__pH6PC {
  padding: 9px 14px 9px 14px;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  color: var(--white);
  background-color: var(--dark-charcoal);
}
.Plans_premium-button__t61Pu:hover,
.Plans_basic-button__pH6PC:hover {
  opacity: 0.8;
}
.Plans_premium-head__9dCBe {
  background-color: var(--off-white);
  padding: 15px;
}
.Plans_premium-body__gozTP {
  color: var(--brown);
  margin: 15px;
}
.Plans_premium-container__E8xpk {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  box-shadow: 0px 12px 16px -4px #00000014;
  margin: 0px !important;
}

@media (max-width: 1400px) {
  .Plans_container__CG051 {
    flex-wrap: wrap;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/components/Plans.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;EACT,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,uBAAuB;EACvB,SAAS;EACT,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;AACA;EACE,0BAA0B;EAC1B,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,mBAAmB;EACnB,8BAA8B;AAChC;AACA;EACE,0BAA0B;EAC1B,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,mBAAmB;EACnB,sCAAsC;AACxC;AACA;;EAEE,YAAY;AACd;AACA;EACE,kCAAkC;EAClC,aAAa;AACf;AACA;EACE,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;EAClB,wCAAwC;EACxC,sBAAsB;AACxB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".container {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n  gap: 30px;\n  margin-top: 60px;\n  margin-bottom: 60px;\n}\n.column {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: flex-start;\n  gap: 15px;\n  border-radius: 8px;\n}\n.row {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n.premium-button {\n  padding: 9px 14px 9px 14px;\n  border-radius: 10px;\n  cursor: pointer;\n  border: none;\n  color: var(--white);\n  background-color: var(--brown);\n}\n.basic-button {\n  padding: 9px 14px 9px 14px;\n  border-radius: 10px;\n  cursor: pointer;\n  border: none;\n  color: var(--white);\n  background-color: var(--dark-charcoal);\n}\n.premium-button:hover,\n.basic-button:hover {\n  opacity: 0.8;\n}\n.premium-head {\n  background-color: var(--off-white);\n  padding: 15px;\n}\n.premium-body {\n  color: var(--brown);\n  margin: 15px;\n}\n.premium-container {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  border-radius: 8px;\n  box-shadow: 0px 12px 16px -4px #00000014;\n  margin: 0px !important;\n}\n\n@media (max-width: 1400px) {\n  .container {\n    flex-wrap: wrap;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Plans_container__CG051`,
	"column": `Plans_column__m-iNw`,
	"row": `Plans_row__gFUuM`,
	"premium-button": `Plans_premium-button__t61Pu`,
	"basic-button": `Plans_basic-button__pH6PC`,
	"premium-head": `Plans_premium-head__9dCBe`,
	"premium-body": `Plans_premium-body__gozTP`,
	"premium-container": `Plans_premium-container__E8xpk`
};
export default ___CSS_LOADER_EXPORT___;
