// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Categories_title__CGivC {
  text-align: center;
  color: (--errie-black);
  font-size: 48px;
  font-weight: 500;
}
.Categories_cards_container__Pp-fJ {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 50px;
  margin-bottom: 50px;
}

@media (max-width: 600px) {
}

/* Media query for screens between 601px and 900px (e.g., tablets) */
@media (min-width: 601px) and (max-width: 900px) {
}

/* Media query for screens between 901px and 1200px (e.g., larger tablets and small desktops) */
@media (min-width: 901px) and (max-width: 1200px) {
}

/* Media query for screens larger than 1200px (e.g., large desktops) */
@media (min-width: 1201px) {
}
`, "",{"version":3,"sources":["webpack://./src/styles/pages/Categories.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;AACA;;AAEA,oEAAoE;AACpE;AACA;;AAEA,+FAA+F;AAC/F;AACA;;AAEA,sEAAsE;AACtE;AACA","sourcesContent":[".title {\n  text-align: center;\n  color: (--errie-black);\n  font-size: 48px;\n  font-weight: 500;\n}\n.cards_container {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n  margin-top: 50px;\n  margin-bottom: 50px;\n}\n\n@media (max-width: 600px) {\n}\n\n/* Media query for screens between 601px and 900px (e.g., tablets) */\n@media (min-width: 601px) and (max-width: 900px) {\n}\n\n/* Media query for screens between 901px and 1200px (e.g., larger tablets and small desktops) */\n@media (min-width: 901px) and (max-width: 1200px) {\n}\n\n/* Media query for screens larger than 1200px (e.g., large desktops) */\n@media (min-width: 1201px) {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `Categories_title__CGivC`,
	"cards_container": `Categories_cards_container__Pp-fJ`
};
export default ___CSS_LOADER_EXPORT___;
