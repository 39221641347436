// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CountdownTimer_containerA__eCXnk {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.CountdownTimer_borderA__gcITE {
  border: 1px solid var(--white);
  border-radius: 5px;
  padding: 5px;
}
.CountdownTimer_containerB__6KSV7 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: var(--brown);
  font-size: 20px;
}

/* Media query for screens smaller than 600px (e.g., smartphones) */
@media (max-width: 600px) {
  .CountdownTimer_containerA__eCXnk {
    gap: 3px;
  }
  .CountdownTimer_borderA__gcITE {
    padding: 2px;
    font-size: 15px;
  }
}

/* Media query for screens between 601px and 900px (e.g., tablets) */
@media (min-width: 601px) and (max-width: 900px) {
  .CountdownTimer_containerA__eCXnk {
    gap: 5px;
  }
  .CountdownTimer_borderA__gcITE {
    padding: 5px;
    font-size: 15px;
  }
}

/* Media query for screens between 901px and 1200px (e.g., larger tablets and small desktops) */
@media (min-width: 901px) and (max-width: 1200px) {
}

/* Media query for screens larger than 1200px (e.g., large desktops) */
@media (min-width: 1201px) {
}
`, "",{"version":3,"sources":["webpack://./src/styles/components/CountdownTimer.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;AACV;AACA;EACE,8BAA8B;EAC9B,kBAAkB;EAClB,YAAY;AACd;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;EACR,mBAAmB;EACnB,eAAe;AACjB;;AAEA,mEAAmE;AACnE;EACE;IACE,QAAQ;EACV;EACA;IACE,YAAY;IACZ,eAAe;EACjB;AACF;;AAEA,oEAAoE;AACpE;EACE;IACE,QAAQ;EACV;EACA;IACE,YAAY;IACZ,eAAe;EACjB;AACF;;AAEA,+FAA+F;AAC/F;AACA;;AAEA,sEAAsE;AACtE;AACA","sourcesContent":[".containerA {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 5px;\n}\n.borderA {\n  border: 1px solid var(--white);\n  border-radius: 5px;\n  padding: 5px;\n}\n.containerB {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 5px;\n  color: var(--brown);\n  font-size: 20px;\n}\n\n/* Media query for screens smaller than 600px (e.g., smartphones) */\n@media (max-width: 600px) {\n  .containerA {\n    gap: 3px;\n  }\n  .borderA {\n    padding: 2px;\n    font-size: 15px;\n  }\n}\n\n/* Media query for screens between 601px and 900px (e.g., tablets) */\n@media (min-width: 601px) and (max-width: 900px) {\n  .containerA {\n    gap: 5px;\n  }\n  .borderA {\n    padding: 5px;\n    font-size: 15px;\n  }\n}\n\n/* Media query for screens between 901px and 1200px (e.g., larger tablets and small desktops) */\n@media (min-width: 901px) and (max-width: 1200px) {\n}\n\n/* Media query for screens larger than 1200px (e.g., large desktops) */\n@media (min-width: 1201px) {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerA": `CountdownTimer_containerA__eCXnk`,
	"borderA": `CountdownTimer_borderA__gcITE`,
	"containerB": `CountdownTimer_containerB__6KSV7`
};
export default ___CSS_LOADER_EXPORT___;
