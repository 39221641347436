// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Otp_container__vDDuQ {
  background-color: var(--white);
  width: 400px;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
}
.Otp_resend__mGvYP {
  text-align: center;
  cursor: pointer;
  color: var(--brown);
}
.Otp_main_text__JclpI {
  color: var(--black);
  font-size: 20px;
  font-weight: 600;
}
.Otp_sub_text__ySwXC {
  max-width: 300px;
  text-align: center;
  color: var(--placeholder-text);
  font-size: 14px;
  font-weight: 400;
}
.Otp_button__eTEGv {
  width: 100%;
  cursor: pointer;
  color: var(--white);
  background-color: var(--brown);
  padding: 13px;
  border-radius: 10px;
  border: none;
}
/* Media query for screens smaller than 600px (e.g., smartphones) */
@media (max-width: 600px) {
  .Otp_container__vDDuQ {
    width: 250px;
  }
}

/* Media query for screens between 601px and 900px (e.g., tablets) */
@media (min-width: 601px) and (max-width: 900px) {
}

/* Media query for screens between 901px and 1200px (e.g., larger tablets and small desktops) */
@media (min-width: 901px) and (max-width: 1200px) {
}

/* Media query for screens larger than 1200px (e.g., large desktops) */
@media (min-width: 1201px) {
}
`, "",{"version":3,"sources":["webpack://./src/styles/components/Otp.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;AACX;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,8BAA8B;EAC9B,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,eAAe;EACf,mBAAmB;EACnB,8BAA8B;EAC9B,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;AACA,mEAAmE;AACnE;EACE;IACE,YAAY;EACd;AACF;;AAEA,oEAAoE;AACpE;AACA;;AAEA,+FAA+F;AAC/F;AACA;;AAEA,sEAAsE;AACtE;AACA","sourcesContent":[".container {\n  background-color: var(--white);\n  width: 400px;\n  padding: 20px;\n  border-radius: 10px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 25px;\n}\n.resend {\n  text-align: center;\n  cursor: pointer;\n  color: var(--brown);\n}\n.main_text {\n  color: var(--black);\n  font-size: 20px;\n  font-weight: 600;\n}\n.sub_text {\n  max-width: 300px;\n  text-align: center;\n  color: var(--placeholder-text);\n  font-size: 14px;\n  font-weight: 400;\n}\n.button {\n  width: 100%;\n  cursor: pointer;\n  color: var(--white);\n  background-color: var(--brown);\n  padding: 13px;\n  border-radius: 10px;\n  border: none;\n}\n/* Media query for screens smaller than 600px (e.g., smartphones) */\n@media (max-width: 600px) {\n  .container {\n    width: 250px;\n  }\n}\n\n/* Media query for screens between 601px and 900px (e.g., tablets) */\n@media (min-width: 601px) and (max-width: 900px) {\n}\n\n/* Media query for screens between 901px and 1200px (e.g., larger tablets and small desktops) */\n@media (min-width: 901px) and (max-width: 1200px) {\n}\n\n/* Media query for screens larger than 1200px (e.g., large desktops) */\n@media (min-width: 1201px) {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Otp_container__vDDuQ`,
	"resend": `Otp_resend__mGvYP`,
	"main_text": `Otp_main_text__JclpI`,
	"sub_text": `Otp_sub_text__ySwXC`,
	"button": `Otp_button__eTEGv`
};
export default ___CSS_LOADER_EXPORT___;
